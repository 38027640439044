<template>
  <div class="g28">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleSaveClick">保存</el-button>
          <el-button v-if="errorKeys.length" type="primary" @click="handleErrorClick">下一错误</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div ref="spreadContainer" class="spread-container">
    </div>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import SpreadMixin from '@/mixins/SpreadMixin'
import auth from '@/common/auth'
import g13Service from '@/services/g13Service'
import g28Service from '@/services/g28Service'
import G28SpreadInfo from '@/views/PlanStatManage/HeaderColumns/G28SpreadInfo'
import G28Model from '@/model/G28Model'
import utility from '@/common/utility'
import { mapGetters } from 'vuex'

export default {
  name: 'g28',
  mixins: [
    SpreadMixin
  ],
  components: {
    MySearchBar
  },
  data () {
    return {
      materialList: [],
      materialListMap: {},
      g28List: [],
      spreadInfo: G28SpreadInfo,
      currentErrorIndex: 0
    }
  },
  computed: {
    errorKeys () {
      return Object.keys(this.errorMap)
    },
    tenderGuid () {
      return auth.getUserInfo().tenderGuid
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      month: 'getMonth',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  methods: {
    handleSaveClick () {
      // 检查是否有错误
      if (Object.keys(this.errorMap).length) {
        this.$message({
          type: 'error',
          message: '数据存在问题，请修正后再保存！'
        })
        return
      }
      this.translate()
      this.pushData()
    },
    handleErrorClick () {
      let rowIndex = this.errorMap[this.errorKeys[this.currentErrorIndex]].rowIndex
      this.worksheet.showRow(rowIndex, GC.Spread.Sheets.VerticalPosition.top)
      this.currentErrorIndex = (this.currentErrorIndex + 1) % this.errorKeys.length
    },
    setProtection () {
      this.worksheet.options.isProtected = true
      let unLockStyle = new GC.Spread.Sheets.Style()
      unLockStyle.backColor = '#aea'
      unLockStyle.locked = false

      this.worksheet.setStyle(-1, 6, unLockStyle)
    },
    bindCellChanged () {
      this.worksheet.bind(GC.Spread.Sheets.Events.ValueChanged, (sender, info) => {
        this.rowCompute(info.row)
      })
    },
    bindDragFillBlockCompleted () {
      this.worksheet.bind(GC.Spread.Sheets.Events.DragFillBlockCompleted, (e, info) => {
        for (let i = 0; i < info.fillRange.rowCount; ++i) {
          this.rowCompute(info.fillRange.row + i)
        }
      })
    },
    bindClipboardPasted () {
      this.worksheet.bind(GC.Spread.Sheets.Events.ClipboardPasted, (sender, info) => {
        for (let i = 0; i < info.cellRange.rowCount; ++i) {
          this.rowCompute(i + info.cellRange.row)
        }
      })
    },
    rowCompute (rowIndex) {
      let currentMonthNum = this.worksheet.getValue(rowIndex, 6)

      let flag = false

      if (currentMonthNum) {
        flag = true
      }

      if (utility.isNumber(currentMonthNum)) {
        let totalMonthNum = utility.floatAdd(currentMonthNum, this.materialListMap[rowIndex].currentYearAggregationNum)
        let totalNum = utility.floatAdd(currentMonthNum, this.materialListMap[rowIndex].totalAggregationNum)

        this.worksheet.setValue(rowIndex, 7, totalMonthNum)
        this.worksheet.setValue(rowIndex, 8, totalNum)
      } else {
        this.worksheet.setValue(rowIndex, 7, '')
        this.worksheet.setValue(rowIndex, 8, '')
      }

      if (flag && !utility.isNumber(currentMonthNum)) {
        this.setError('请检查数据是否填写正确', rowIndex, 7)
      } else {
        this.setError(null, rowIndex, 7)
      }
      this.currentErrorIndex = 0
    },
    translate () {
      let dataTable = this.spread.toJSON({
        ignoreStyle: true
      }).sheets[this.spreadInfo.sheetName].data.dataTable
      console.log(dataTable)

      this.g28List = []
      for (let key in Object.keys(dataTable)) {
        let item = dataTable[key]

        if (!item[5] || !utility.isNumber(item[5].value)) {
          continue
        }

        let g28Model = new G28Model({ planStatExaminationGuid: this.planStatExaminationGuid, materialListGuid: this.materialListMap[parseInt(key)].materialListGuid, tenderGuid: auth.getUserInfo().tenderGuid, year: this.year, month: this.month })
        g28Model.currentYearNum = parseFloat(item[5].value)
        g28Model.currentMonthNum = parseFloat(item[6].value)
        g28Model.totalMonthNum = parseFloat(item[7].value)
        g28Model.totalNum = parseFloat(item[8].value)
        g28Model.init()

        if (utility.isNumber(g28Model.currentMonthNum) && g28Model.currentMonthNum !== 0) {
          this.g28List.push(g28Model)
        }
      }
    },
    pushData () {
      g28Service.batchAdd(this.g28List)
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '操作成功！'
            })
            this.$router.push('/g28Index')
          } else {
            if (res.data.data.length) {
              res.data.data.forEach(item => {
                this.setError('请检查数据是否填写正确', this.materialListMap[item.materialListGuid].index, 7)
              })
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '操作失败！'
          })
        })
    },
    bindMaterialList () {
      // 设定表格的行数
      this.worksheet.setRowCount(this.materialList.length)
      let dataArray = []
      console.log('materialList', this.materialList)
      // 绑定数据
      this.materialList.forEach((item, index) => {
        let rowData = []
        rowData.push(index + 1)
        rowData.push(item.parentMaterialList.materialListName)
        rowData.push(item.materialListName)
        rowData.push(item.unit)
        rowData.push(item.num)
        rowData.push(item.currentYearNum)
        for (let i = 0; i < 3; ++i) {
          rowData.push('')
        }
        dataArray.push(rowData)

        // 附带算一下materialListMap
        item.index = index
        this.materialListMap[index] = item
        this.materialListMap[item.materialListGuid] = item
      })
      this.worksheet.setArray(0, 0, dataArray)
    },
    getG28List () {
      g28Service.list({ tenderGuid: this.tenderGuid, planStatExaminationGuid: this.planStatExaminationGuid })
        .then(res => {
          this.getMaterialList(res.data.data.map(item => item.materialListGuid))
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败！'
          })
        })
    },
    getMaterialList (exceptData) {
      if (!exceptData) {
        exceptData = []
      }
      g13Service.aggregation(this.tenderGuid, this.year, this.month)
        .then(res => {
          if (res.data.code === 1) {
            this.materialList = res.data.data.filter(item => {
              if (exceptData.indexOf(item.materialListGuid) !== -1) {
                return false
              }
              return true
            })

            this.bindMaterialList()
            this.setProtection()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  mounted () {
    this.initWorkbook(this.$refs.spreadContainer)
    this.getG28List()
    this.bindCellChanged()
    this.bindClipboardPasted()
    this.bindDragFillBlockCompleted()
  }
}
</script>

<style scoped lang="scss">
.g28 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-container {
    flex: 1;
  }
}
</style>
